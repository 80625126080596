<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit RTI incident details</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row>
                        <v-select
                            label="Weather conditions at time of incident"
                            v-model="weatherConditionsIdLocal"
                            :items="lookups.allWeatherConditions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Road conditions at time of incident"
                            v-model="roadConditionsIdLocal"
                            :items="lookups.allRoadConditions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Did the incident occur in the dark?"
                            v-model="wasInDarkIdLocal"
                            :items="lookups.allBoolOptions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <div v-if="occurredInDark">

                        <v-row>
                            <v-select
                                label="If Yes, was there street lighting in the area?"
                                v-model="wasStreetLightingIdLocal"
                                :items="lookups.allBoolOptions"
                                item-text="name"
                                item-value="id"
                                clearable
                            ></v-select>
                        </v-row>
                    
                        <v-row v-if="wasStreetLighting">
                            <v-text-field
                                label="If Yes, how far is the nearest street light? (m)"
                                v-model="distanceToNearestStreetLightLocal"
                                type="number"
                                hide-spin-buttons
                            ></v-text-field>
                        </v-row>
                        
                    </div>

                    <v-row>
                        <v-text-field
                            label="Vehicle registration number"
                            v-model="regNumberLocal"
                        ></v-text-field>
                    </v-row>

                    <v-row>
                        <v-select
                            label="Is the driver the owner of the vehicle?"
                            v-model="isDriverOwnerIdLocal"
                            :items="lookups.allLiabilityOptions"
                            item-text="name"
                            item-value="id"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-textarea
                            label="Driver details"
                            v-model="driverDetailsLocal"
                            hint="If the driver is still at the scene, please record their name, insurance company, policy number, and their contact details/address"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-text-field
                            label="Police report reference number"
                            v-model="policeReportRefNumberLocal"
                        ></v-text-field>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Vehicle tow information"
                            v-model="vehicleTowInfoLocal"
                            hint="If vehicle has been towed before you could record the registration number, please detail which company was used by police to tow the vehicle"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Additional info relating to the RTI"
                            v-model="additionalInfoLocal"
                            hint="Please give any additional information relating to this RTI/ERS which you think may be relevant"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";
import optionSetAgent from "../../Ods/optionSetAgent.js";

export default {
    props: {
        id: String,
        weatherConditionsId: Number,
        roadConditionsId: Number,
        wasInDarkId: Number,
        wasStreetLightingId: Number,
        distanceToNearestStreetLight: Number,
        regNumber: String,
        isDriverOwnerId: Number,
        driverDetails: String,
        policeReportRefNumber: String,
        vehicleTowInfo: String,
        additionalInfo: String,
    },
    
    data: function () {
        return {
            weatherConditionsIdLocal: this.weatherConditionsId,
            roadConditionsIdLocal: this.roadConditionsId,
            wasInDarkIdLocal: this.wasInDarkId,
            wasStreetLightingIdLocal: this.wasStreetLightingId,
            distanceToNearestStreetLightLocal: this.distanceToNearestStreetLight,
            regNumberLocal: this.regNumber,
            isDriverOwnerIdLocal: this.isDriverOwnerId,
            driverDetailsLocal: this.driverDetails,
            policeReportRefNumberLocal: this.policeReportRefNumber,
            vehicleTowInfoLocal: this.vehicleTowInfo,
            additionalInfoLocal: this.additionalInfo,

            lookups: {
                allWeatherConditions: [],
                allRoadConditions: [],
                allBoolOptions: [],
                allLiabilityOptions: [],
            },

            isValid: true,

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        occurredInDark: function () {
            return this.wasInDarkIdLocal === 313755;    // Yes
        },

        wasStreetLighting: function () {
            return this.wasStreetLightingIdLocal === 313755;    // Yes
        },
    },

    mounted: function () {
        var l = this.$coreUi.loading();

        Promise.all([
            optionSetAgent.getOptionSetValues("incident-conditions-weather").then(data => {
                this.lookups.allWeatherConditions = data.optionSetValueProperties;

                if (this.lookups.allWeatherConditions.every(x => x.id !== this.weatherConditionsIdLocal))
                    this.weatherConditionsIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("incident-conditions-road").then(data => {
                this.lookups.allRoadConditions = data.optionSetValueProperties;

                if (this.lookups.allRoadConditions.every(x => x.id !== this.roadConditionsIdLocal))
                    this.roadConditionsIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-yes-no").then(data => {
                this.lookups.allBoolOptions = data.optionSetValueProperties;

                if (this.lookups.allBoolOptions.every(x => x.id !== this.wasInDarkIdLocal))
                     this.wasInDarkIdLocal = null;

                if (this.lookups.allBoolOptions.every(x => x.id !== this.wasStreetLightingIdLocal))
                     this.wasStreetLightingIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-liability-details-ynu").then(data => {
                this.lookups.allLiabilityOptions = data.optionSetValueProperties;

                if (this.lookups.allLiabilityOptions.every(x => x.id !== this.isDriverOwnerIdLocal))
                     this.isDriverOwnerIdLocal = null;
            }).catch(console.error),

        ]).then(() => {
            l.dismiss();
            this.refreshValidation();
        });
    },

    methods: {
        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.formBuilder.formData["vm-rti-details-weather-conditions-lov"] = this.weatherConditionsIdLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-road-conditions-lov"] = this.roadConditionsIdLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-in-dark-bool"] = this.wasInDarkIdLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-street-lighting-bool"] = this.occurredInDark ? this.wasStreetLightingIdLocal : null;
                result.aspectData.formBuilder.formData["vm-rti-details-nearest-light-int"] = (this.occurredInDark && this.wasStreetLighting) ? this.distanceToNearestStreetLightLocal : null;
                result.aspectData.formBuilder.formData["vm-rti-details-vrn-text"] = this.regNumberLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-vehicle-owner-bool"] = this.isDriverOwnerIdLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-driver-details-text"] = this.driverDetailsLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-police-report-ref-text"] = this.policeReportRefNumberLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-tow-info-text"] = this.vehicleTowInfoLocal;
                result.aspectData.formBuilder.formData["vm-rti-details-additional-info-text"] = this.additionalInfoLocal;

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

               }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>